import { reactive } from 'vue'
import toast from '@/plugins/toast'

const initial = (rules, defaults) => {
  const model = {}
  for (const [key] of Object.entries(rules)) {
    model[key] = defaults[key] || null
  }

  return reactive(model)
}

const validate = (v$) => new Promise((resolve) => {
  v$.value.$validate().then((valid) => {
    if (! valid) {
      toast.errorForm(v$)

      resolve(false)
    }

    resolve(true)
  })
})

export default {
  initial,
  validate
}
