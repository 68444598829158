<script setup>

import { onMounted, ref } from "vue"
import { useXmStore } from "@/store/xm"
import { useRoute } from 'vue-router'

import FormXm from "@/components-uhas/forms/FormXm.vue"

const xmStore = useXmStore()
const route = useRoute()

const xm = ref({ _: false })
const loading = ref(false)

onMounted(() => {
  loading.value = true
  xmStore.show(route.params.id).then(({ data }) => {
    if (data.error) {
      return
    }

    xm.value = { ...data.xm }

    loading.value = false
  })
})

</script>

<template>
  <div class="block-section">
    <div class="block-header">
        <span class="block-title">
          <div>Show #{{ xm.id }}</div>
        </span>

        <div class="flex align-items-center justify-content-center">
          <Button label="Back" class="p-button-text p-button-plain" @click="$router.go(-1)" />
        </div>
    </div>
    <div class="block-content">
        <div class="surface-ground px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
          <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
            <div>
              
              <FormXm
                v-if="xm._ !== false"
                v-model="xm"
              />

            </div>
          </div>
        </div>
    </div>
  </div>
</template>
