<script setup>
  import { computed, defineEmits, defineProps, ref, watch } from 'vue'
  import { useVuelidate } from "@vuelidate/core"

	import { toDateTime } from '@/scripts/datetime'
  import form from '@/scripts/form'

  const changed = ref(false)
  const loading = ref(false)

  const emit = defineEmits(['update:modelValue'])
  const props = defineProps(['id', 'modelValue'])

  const modelValue = computed(() => props.modelValue)

  const rules = {
    server_name: {},
    campaign_id: {},
    validated: {},
    account_type: {},
    login_id: {},
    client_id: {},
    archived: {},
    activation_date: {},
    signup_date: {},
    campaign_name: {},
    country: {},
    referral_url: {},
    trading_platform: {},
    lots: {},
    commission: {},
    created_at: {},
    updated_at: {},

		xm_trade_statistics: {},
  }

  const model = form.initial(rules, modelValue.value)

  const v$ = useVuelidate(rules, model)

  watch(model, (newValue) => {
    changed.value = true

    emit('update:modelValue', newValue)
  })

  loading.value = false
</script>

<template>
  <label class="block text-900 font-medium mb-2" for="server_name">Server Name</label>
  <InputText v-model="v$.server_name.$model" id="server_name" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="campaign_id">Campaign ID</label>
  <InputText v-model="v$.campaign_id.$model" id="campaign_id" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="validated">Validated</label>
  <InputText :value="v$.validated.$model ? 'true' : 'false'" id="validated" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="account_type">Account Type</label>
  <InputText v-model="v$.account_type.$model" id="account_type" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="login_id">Login ID</label>
  <InputText v-model="v$.login_id.$model" id="login_id" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="client_id">Client ID</label>
  <InputText v-model="v$.client_id.$model" id="client_id" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="archived">Archived</label>
  <InputText :value="v$.archived.$model ? 'true' : 'false'" id="archived" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="activation_date">Activation Date</label>
  <InputText :value="v$.activation_date.$model ? (new Date(v$.activation_date.$model)).toLocaleString() : ''" id="activation_date" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="signup_date">Signup Date</label>
  <InputText :value="(new Date(v$.signup_date.$model)).toLocaleDateString()" id="signup_date" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="campaign_name">Campaign Name</label>
  <InputText v-model="v$.campaign_name.$model" id="campaign_name" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="country">Country</label>
  <InputText v-model="v$.country.$model" id="country" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="referral_url">Referral Url</label>
  <InputText v-model="v$.referral_url.$model" id="referral_url" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="trading_platform">Trading Platform</label>
  <InputText v-model="v$.trading_platform.$model" id="trading_platform" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="lots">Lots</label>
  <InputText v-model="v$.lots.$model" id="lots" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="commission">Commission</label>
  <InputText v-model="v$.commission.$model" id="commission" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="created_at">Created At</label>
  <InputText :value="toDateTime(modelValue.created_at)" id="created_at" type="text" class="w-full mb-3" readonly />

  <label class="block text-900 font-medium mb-2" for="updated_at">Updated At</label>
  <InputText :value="toDateTime(modelValue.updated_at)" id="updated_at" type="text" class="w-full mb-3" readonly />

	<Divider />

  <DataTable
    class="p-datatable-gridlines"
    dataKey="id"
    :lazy="true"
    :loading="loading"
    responsiveLayout="scroll"
    :rowHover="true"
    :value="v$.xm_trade_statistics.$model"
  >
    <template #empty>
			No records.
    </template>
    <template #loading>
			Loading. Please wait.
    </template>

    <Column header="Year/Month">
      <template #body="{data}">
        {{ data.year }} / {{ data.month }}
      </template>
    </Column>

    <Column field="lots" header="Lots" />

    <Column field="commission" header="Commission" />

    <Column field="date_latest" header="Latest" />

  </DataTable>
</template>
